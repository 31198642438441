<template>
  <div class="Orderdetails_minibox">
    <h2>{{$t("sabre.booking.order-detail-box.flight-details")}}</h2>

    <order-detail-mini-one-box :data="flight.legs[0]"/>
    <order-detail-mini-one-box :isBack="true" :data="flight.legs[1]" v-if="!isOneWay"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'order-detail-mini-box',
  components: {
    OrderDetailMiniOneBox: () => import('./orderDetailMiniOneBox'),
  },
  computed: {
    ...mapGetters({
      directionList: 'GET_SABRE_FLIGHT_DIRECTION_LIST',
      flight: 'GET_SABRE_ORDERED_FLIGHT',
      condition: 'GET_SABRE_ORDERED_CONDITION',
    }),
    isOneWay() {
      const { condition, directionList } = this;
      return condition.flightDirection === directionList[1];
    },
  },
};
</script>
